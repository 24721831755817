import { useState, type ReactNode, useEffect } from "react";
import clsx from "clsx";
import { useStore } from '@nanostores/react';
import { nav } from "@assets/js/nav";
import Icon from "@react/widget/Icon";
import Button from "@react/widget/Button";
import { translateGlossaries as ts } from "@i18next/client/utils";

interface Props extends Record<string, any> {
  currentSite: Page.Site;
  glossaries?: Record<string, string>;
  title?: string;
  className?: string;
  classNames?: {
    headline?: string;
    headlineLabel?: string;
    headlineIcon?: string;
    context?: string;
    contextBack?: string;
    contextClose?: string;
    contextContainer?: string;
  };
  headline?: ReactNode;
  headlineAttrs?: Record<string, any>;
  context?: ReactNode;
  contextAttrs?: Record<string, any>;
  mask?: ReactNode;
};

const Dropdown:React.FC<Props> = ({
    currentSite,
    glossaries: gs,
    title,
    className = "",
    classNames = {},
    headline, headlineAttrs = {},
    context, contextAttrs = {},
    mask,
    ...props
  }) => {
  const [key, setKey] = useState(title ?? (Math.random() * 100000).toFixed(0));
  const $nav = useStore(nav);
  const isOpen = $nav === key;
  useEffect(() => {
    var nav = document.getElementById("header-nav");
    if(!!$nav && $nav !== "none" && !!nav)
      nav.dataset.nav = "open";
  }, [$nav]);
  return (
    <div className={clsx("dropdown block group/drop top-nav-item xl:px-4", currentSite.locale == "de" ? "lg:px-1" : "lg:px-1.5", className)} {...props} data-open={isOpen ? "" : undefined}>
      <button type="button" className={clsx("group/headline headline w-full bg-transparent border-0 justify-between text-left container relative py-3.5 flex cursor-pointer items-center lg:p-0 lg:h-8 lg:justify-between after:absolute after:-bottom-1 after:border-[10px] after:border-b-[12px] after:border-t-0 after:border-transparent after:translate-y-6 after:left-[calc(50%-12px)] after:border-b-gray-50 after:transition-all after:opacity-0 after:invisible group-data-[open]/drop:after:opacity-100 group-data-[open]/drop:after:visible", classNames.headline)}
        {...headlineAttrs} onClick={() => nav.set((!isOpen && key) ? key : "none")}
      >
        <span className={clsx("font-semibold relative before:h-0.top-mask5 before:absolute before:inset-x-0 before:bottom-0 group-data-[open]/drop:before:bg-primary group-hover/headline:before:bg-primary", classNames.headlineLabel)}>{headline}</span>
        <Icon className={clsx('ml-0.5 xl:ml-1.5 text-base lg:text-xs transition-transform lg:rotate-90 group-data-[open]/drop:-rotate-90 lg:group-data-[open]/drop:-rotate-90', classNames.headlineIcon)} name='chevron-right' />
      </button>
      <div className={clsx("group/context context overflow-hidden opacity-0 transition-all duration-300 fixed z-40 inset-0 h-auto min-w-full left-full text-sm lg:absolute lg:inset-x-0 lg:inset-y-auto lg:h-0 lg:mt-[29px] bg-white bg-gradient-to-b to-[7px] from-gray-50 group-data-[open]/drop:h-auto group-data-[open]/drop:opacity-100 group-data-[open]/drop:left-0", classNames.context)} {...contextAttrs}>
        <div className={clsx("group/back container py-2.5 w-full flex bg-white border-b lg:hidden", classNames.contextBack)}>
          <Button style='link' className='mobile-nav-back cursor-pointer' size='sm' icon="arrow-left" iconPosition='left' circleArrow onClick={() => nav.set("none")}>{ts(gs,"Back")}</Button>
        </div>
        <div className={clsx("h-[calc(100%-52px)] overflow-y-auto md:container lg:h-auto lg:flex pb-10 sm:pb-0", classNames.contextContainer)}>
          <Icon className={clsx('group/close nav-close absolute hover:cursor-pointer hidden lg:block lg:top-4 lg:right-4 xl:top-8 xl:right-8 text-xl', classNames.contextClose)} name='close' onClick={() => nav.set("none")}/>
          {context}
        </div>
      </div>
      {mask ?? <div className="top-mask hidden absolute overflow-hidden opacity-0 h-0 bg-black/25 inset-x-0 lg:block lg:mt-[29px] z-[39] transition-opacity duration-300 group-data-[open]/drop:h-dvh group-data-[open]/drop:opacity-100" onClick={() => nav.set("none")}></div>}
    </div>
  );
}

export default Dropdown;